export default (theme) => ({
  page: {
    ...theme.page,
    backgroundImage: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('/img/bg-startnow.jpg')",
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'right',
    minHeight: '606px',
    paddingTop: '60px'
  },
  root: {
    ...theme.mainContainer,
    color: 'white'
  },
  section: {
    ...theme.flexColumnCenter,
    margin: '25px auto'
  },
  mainTitle: {
    ...theme.mainTitle
  },
  heroBox: {
    ...theme.heroBox,
    ...theme.flexRowCenter,
    backgroundColor: 'white',
    padding: '40px 15px',
    marginBottom: '100px'
  },
  stepPre: {
    ...theme.fontWeight.regular,
    margin: '10px',
    fontSize: '16px'
  },
  stepTitle: {
    ...theme.fontWeight.extraBold
  },
  stepDesc: {
    textAlign: 'left',
    margin: '20px',
    lineHeight: '30px'
  }
})
