import configJson from "./config.json";

export const { env, firebase } = configJson;

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
  enableLogging: false, // enable/disable Firebase Database Logging
  userProfile: "users",
  enableClaims: true,
  useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
};

export default {
  env,
  firebase,
  reduxFirebase,
};
