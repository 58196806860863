export default {
  palette: {
    primary: {
      main: '#D80000'
    },
    secondary: {
      main: '#FFFFFF'
    }
  },
  colors: {
    gray: '#919191',
    red: '#D80000'
  },
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    useNextVariants: true,
    body1: {
      fontFamily: "'Montserrat', sans-serif"
    },
    h1: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '35x',
      '@media (min-width:600px)': {
        fontSize: '70px'
      }
    },
    h2: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '20px',
      '@media (min-width:600px)': {
        fontSize: '22px'
      }
    },
    h3: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '16px',
      '@media (min-width:600px)': {
        fontSize: '18px'
      }
    },
    h4: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '15px',
      '@media (min-width:600px)': {
        fontSize: '16px'
      }
    }
  },
  fontWeight: {
    regular: { fontWeight: 400 },
    medium: { fontWeight: 500 },
    semiBold: { fontWeight: 600 },
    extraBold: { fontWeight: 800 }
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  page: {
    minHeight: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  featureBox: {
    backgroundColor: 'rgba(242, 242, 242, 0.8);',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    paddingTop: '8px',
    marginTop: '8px'
  },
  mainContainer: {
    paddingTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    fontWeight: 400,
    paddingTop: '1.5rem',
    '@media (max-width:600px)': {
      paddingTop: '0.5rem'
    }
  },
  mainTitle: {
    fontWeight: 400,
    '@media (max-width:600px)': {
      marginTop: '35px'
    },
    textAlign: 'center',
    maxWidth: '825px'
  },
  heroBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '-150px',
    padding: '20px',
    minHeight: '300px'
  },
  accountPage: {
    minHeight: '100%',
    backgroundImage: "linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0)), url('/img/bg-login.jpg')",
    backgroundRepeat: 'repeat',
    backgroundSize: 'auto',
    paddingTop: '60px'
  },
  accountBlock: {
    minHeight: '100%',
    backgroundImage: "url('/img/bg-login.jpg')",
    backgroundRepeat: 'repeat',
    backgroundSize: 'auto'
  },
  accountPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    flexGrow: 1,
    padding: '3rem',
    minWidth: '550px',
    minHeight: '270px',
    margin: '40px 20px 100px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: '100%',
    width: '100%',
    margin: '.2rem',
    '&>div': {
      height: '90px'
    }
  },
  whiteBox: {
    backgroundColor: '#FFFFFF'
  }
}
