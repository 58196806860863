import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

export const isAdmin = (authOrUser, profileOrNull) => {
  const auth = profileOrNull ? authOrUser : authOrUser.auth
  const profile = profileOrNull ? profileOrNull : authOrUser.profile
  return !!(
    !auth.isEmpty &&
    !!auth.uid &&
    profile &&
    profile.token &&
    profile.token.claims &&
    profile.token.claims.admin
  )
}

export const getMember = (authOrUser, profileOrNull) => {
  const auth = profileOrNull ? authOrUser : authOrUser.auth
  const profile = profileOrNull ? profileOrNull : authOrUser.profile
  return (
    (!auth.isEmpty && !!auth.uid && profile && profile.token && profile.token.claims && profile.token.claims.member) ||
    null
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))

export const UserIcon = ({ photoURL }) => {
  const classes = useStyles()
  return photoURL ? <Avatar src={photoURL} className={classes.avatar} /> : <AccountCircle />
}

export const useAuth = () => {
  const [auth, profile] = useSelector(({ firebase: { auth, profile } }) => [auth, profile])
  const loading = !isLoaded(auth) || !isLoaded(profile)
  const exists = isLoaded(auth) && !isEmpty(auth) && isLoaded(profile) && !isEmpty(profile)
  return { auth, profile, exists, loading }
}
