import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Testimonial from 'components/Testimonial'
import { TargetIcon, FootPainIcon, ImproveCirculation, ImprovedBalance } from 'utils/icons'
import { START_NOW_PATH, SUCCESS_PATH } from 'consts/paths'
import styles from './HomePage.styles'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(styles)

function Home() {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.page}>
        <Container component="main" maxWidth="lg" className={classes.root}>
          <img src="/logo.svg" className={classes.brandImg} alt="FOOT DOC in the Box" />
          <Typography className={classes.subTitle}>
            Foot &amp; Leg
            <br /> pain and Numbness <span className={classes.extraBold}>Relief</span>
          </Typography>

          <div className="flex-row-center">
            <div className={classes.section}>
              <Button color="primary" size="large" variant="contained" component={Link} to={START_NOW_PATH}>
                Start Now
              </Button>
            </div>
          </div>
        </Container>
      </div>
      <Container maxWidth="lg" className={classes.heroBox}>
        <Typography variant="h3" style={{ paddingTop: '30px' }}>
          LASTING RESULTS
        </Typography>
        <Grid container spacing={1} className={classes.heroGrid}>
          <Grid item className={classes.heroItem}>
            <div className={classes.heroIcon}>
              <TargetIcon />
            </div>
            <Typography variant="h4" className={classes.heroSub}>
              Foot & Hand <br />
              Pain Relief
            </Typography>
          </Grid>

          <Grid item className={classes.heroItem}>
            <div className={classes.heroIcon}>
              <FootPainIcon />
            </div>
            <Typography variant="h4" className={classes.heroSub}>
              Reduce or <br />
              Eliminate Numbness
            </Typography>
          </Grid>

          <Grid item className={classes.heroItem}>
            <div className={classes.heroIcon}>
              <ImproveCirculation />
            </div>
            <Typography variant="h4" className={classes.heroSub}>
              Improve <br /> Circulation
            </Typography>
          </Grid>

          <Grid item className={classes.heroItem}>
            <div className={classes.heroIcon}>
              <ImprovedBalance />
            </div>
            <Typography variant="h4" className={classes.heroSub}>
              Improved <br />
              Balance
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="xl" className={classes.section}>
        <Typography variant="h3" style={{ marginTop: '110px', marginBottom: '50px' }}>
          SUCCESS STORIES
        </Typography>
        <Grid container spacing={2} className={classes.testGrid}>
          <Testimonial name="Julie H">
            After just one 20 minute session my pain has dramatically decreased. I'm looking forward to continued
            healing with this product.
          </Testimonial>
        </Grid>
        <div className="flex-row-center">
          <div className={classes.section}>
            <Button
              color="primary"
              size="large"
              variant="contained"
              component={Link}
              to={SUCCESS_PATH}
              style={{ margin: '25px 0' }}
            >
              View All
            </Button>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Home
