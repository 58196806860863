export default (theme) => ({
  page: {
    ...theme.page,
    backgroundImage: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('/img/bg-startnow.jpg')",
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'right',
    minHeight: '606px',
    paddingTop: '60px'
  },
  root: {
    ...theme.mainContainer,
    color: 'white'
  },
  section: {
    ...theme.flexColumnCenter,
    margin: '25px auto'
  },
  mainTitle: {
    ...theme.mainTitle
  },
  heroBox: {
    ...theme.heroBox,
    ...theme.flexRowCenter,
    backgroundColor: 'white',
    padding: '40px 15px',
    marginBottom: '100px'
  },
  heroDescription: {
    textAlign: 'left'
  },
  heroTitle: {
    ...theme.fontWeight.extraBold,
    fontSize: '40px'
  },
  heroSubtitle: {
    fontStyle: 'italic',
    margin: '5px 0 15px 0'
  },
  heroList: {
    lineHeight: '30px'
  },
  shopImg: {
    maxWidth: '100%'
  },
  fileBox: {
    lineHeight: '50px',
    textAlign: 'left',
    '& a': {
      color: 'inherit'
    }
  },
  shopify: {
    width: '100%'
  }
})
