export default (theme) => ({
  container: {
    minHeight: '100%'
  },
  copyright: {
    textAlign: 'center',
    width: '100%'
    // clear: 'both',
    // position: 'relative',
    // height: '200px',
    // marginTop: '-200px'
  }
})
