import React from 'react'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isLoaded, isEmpty, useFirebase } from 'react-redux-firebase'
import {
  HOME_PATH,
  LOGIN_PATH,
  START_NOW_PATH,
  HOW_WORKS_PATH,
  SUCCESS_PATH,
  CONTACT_PATH,
  PORTAL_PATH,
  ACCOUNT_PATH
} from 'consts/paths'
import styles from './Footer.styles'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { Container, Grid } from '@material-ui/core'
const useStyles = makeStyles(styles)

function Navbar() {
  const classes = useStyles()
  const history = useHistory()
  const firebase = useFirebase()

  const handleLogout = (e) => {
    e.preventDefault()
    return firebase.logout().then(() => {
      history.push('/')
    })
  }

  // Get auth from redux state
  const auth = useSelector((state) => state.firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)

  return (
    <div className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container spacing={3} className={classes.footerGrid}>
          <Grid item sm={12} md={3} className={classes.footerItem}>
            <Typography color="inherit" component={Link} to={HOME_PATH} className={classes.brand} data-test="brand">
              <img src="/logo.svg" className={classes.brandImg} alt="FOOT DOC in the Box" />
            </Typography>
          </Grid>
          <Grid item sm={12} md={3} className={classes.footerItem}>
            <Typography color="inherit" className={classes.menuLink} component={Link} to={START_NOW_PATH}>
              START NOW
            </Typography>
            <Typography color="inherit" className={classes.menuLink} component={Link} to={HOW_WORKS_PATH}>
              HOW IT WORKS
            </Typography>
            <Typography color="inherit" className={classes.menuLink} component={Link} to={SUCCESS_PATH}>
              SUCCESS STORIES
            </Typography>
            <Typography color="inherit" className={classes.menuLink} component={Link} to={CONTACT_PATH}>
              CONTACT
            </Typography>
          </Grid>
          <Grid item sm={12} md={3} className={classes.footerItem}>
            {/* <Typography className={classes.text}>CONNECT</Typography>
            <Typography color="primary" component={Link} to={CONTACT_PATH} className={classes.socialLink}>
              YOUTUBE
            </Typography>
            <Typography color="primary" component={Link} to={CONTACT_PATH} className={classes.socialLink}>
              FACEBOOK
            </Typography> */}
          </Grid>
          <Grid item sm={12} md={3} className={classes.footerItem}>
            <Typography className={classes.text}>CLIENT PORTAL</Typography>
            {authExists ? (
              <>
                <Typography color="inherit" className={classes.menuLink} component={Link} to={PORTAL_PATH}>
                  PORTAL
                </Typography>
                <Typography color="inherit" className={classes.menuLink} component={Link} to={ACCOUNT_PATH}>
                  ACCOUNT
                </Typography>
                <Typography
                  color="inherit"
                  className={classes.menuLink}
                  component={Link}
                  to="/logout"
                  onClick={handleLogout}
                >
                  SIGN OUT
                </Typography>
              </>
            ) : (
              <Button className={classes.signIn} component={Link} to={LOGIN_PATH}>
                LOGIN NOW
              </Button>
            )}
          </Grid>
        </Grid>
        <Typography className={classes.text} style={{ textAlign: 'center', marginTop: '25px' }}>
          ©2021 Foot Doc in the Box™
        </Typography>
      </Container>
    </div>
  )
}

export default Navbar
