import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CoreLayout from '../layouts/CoreLayout'
import Home from './Home'
import StartNow from './StartNow'
import HowItWorks from './HowItWorks'
import SuccessStories from './SuccessStories'
import Contact from './Contact'
import LoginRoute from './Login'
import SignupRoute from './Signup'
import PortalRoute from './Portal'
import AccountRoute from './Account'
import NotFoundRoute from './NotFound'
import NotMemberRoute from './NotMember'
import UsersRoute from './Users'
import CMSRoute from './CMS'

export default function createRoutes(store) {
  return (
    <CoreLayout>
      <Switch>
        <Route exact path={Home.path} component={() => <Home.component />} />
        {
          /* Build Route components from routeSettings */
          [
            AccountRoute,
            PortalRoute,
            StartNow,
            HowItWorks,
            SuccessStories,
            Contact,
            SignupRoute,
            LoginRoute,
            NotMemberRoute,
            UsersRoute,
            CMSRoute
            /* Add More Routes Here */
          ].map((settings, index) => (
            <Route key={`Route-${index}`} {...settings} />
          ))
        }
        <Route component={NotFoundRoute.component} />
      </Switch>
    </CoreLayout>
  )
}
