import { Loadable } from 'utils/components'
import { LOGIN_PATH as path } from 'consts/paths'

export default {
  path,
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: 'Login' */ './components/LoginPage')
  })
}
