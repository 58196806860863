export default function () {
  var client = window.ShopifyBuy.buildClient({
    domain: 'footdocinthebox.myshopify.com',
    storefrontAccessToken: '180cff653ecabe452dd54c1a8c4765b0'
  })

  const rightCol = {
    'font-family': "'Montserrat', sans-serif",
    'margin-left': '20px',
    '@media (min-width: 601px)': {
      'margin-left': 'calc(50% + 10px)!important'
    }
  }

  window.ShopifyBuy.UI.onReady(client).then(function (ui) {
    const node = document.getElementById('collection-component-1617768202631')

    if (node.children.length > 0) return
    ui.createComponent('collection', {
      id: '261373755573',
      node,
      moneyFormat: '%24%7B%7Bamount%7D%7D',
      options: {
        product: {
          styles: {
            product: {
              '@media (min-width: 601px)': {
                'margin-left': '20px',
                'margin-bottom': '50px'
              },
              width: '100%',
              position: 'relative',
              '@media (min-width: 601px)': {
                height: '350px'
              },
              'margin-bottom': '20px'
            },
            img: {
              'max-height': '100%'
            },
            imgWrapper: {
              position: 'relative',
              height: '300px',
              overflow: 'hidden',
              '@media (min-width: 601px)': {
                width: '50%!important'
              }
            },
            title: {
              ...rightCol,
              'font-size': '40px',
              'font-weight': 800,
              'letter-spacing': 0,
              'line-height': '44px'
            },
            buttonWithQuantity: {
              // bottom: '50px',
              // position: 'absolute'
            },
            button: {
              'font-family': 'Montserrat, sans-serif',
              'font-weight': 'bold',
              ':hover': {
                'background-color': '#ff0000'
              },
              'background-color': '#d80000',
              ':focus': {
                'background-color': '#ff0000'
              }
            },
            prices: {
              ...rightCol
            },
            price: {
              'font-size': '22px',
              'font-weight': 500,
              'letter-spacing': 0,
              'line-height': '30px'
            },
            description: {
              ...rightCol,
              'font-size': '16px',
              'font-weight': 300,
              'letter-spacing': 0,
              'line-height': '30px',
              height: '90px',
              overflow: 'hidden'
            }
          },
          order: [
            'img',
            'imgWithCarousel',
            'title',
            'variantTitle',
            'price',
            'options',
            'description',
            'quantity',
            'button',
            'buttonWithQuantity'
          ],
          text: {
            button: 'ADD TO CART'
          },
          googleFonts: ['Montserrat'],
          layout: 'horizontal',
          contents: {
            description: true,
            button: false,
            buttonWithQuantity: true // determines whether to show any quantity inputs at all
          },
          classes: {
            wrapper: 'shopify-buy__product-wrapper',
            product: 'shopify-buy__product',
            img: 'shopify-buy__product__variant-img',
            imgWrapper: 'shopify-buy__product-img-wrapper',
            carousel: 'shopify-buy__carousel',
            carouselNext: 'carousel-button--next',
            carouselPrevious: 'carousel-button--previous',
            carouselItem: 'shopify-buy__carousel-item',
            carouselItemSelected: 'shopify-buy__carousel-item--selected',
            blockButton: 'shopify-buy__btn--parent',
            button: 'shopify-buy__btn',
            buttonWrapper: 'shopify-buy__btn-wrapper',
            title: 'shopify-buy__product__title',
            prices: 'shopify-buy__product__price',
            price: 'shopify-buy__product__actual-price',
            compareAt: 'shopify-buy__product__compare-price',
            unitPrice: 'shopify-buy__product__unit-price',
            loweredPrice: 'shopify-buy__price--lowered',
            variantTitle: 'shopify-buy__product__variant-title',
            description: 'shopify-buy__product-description',
            options: 'shopify-buy__product__variant-selectors',
            disabled: 'shopify-buy__btn-disabled',
            buttonBesideQty: 'shopify-buy__beside-quantity',
            quantity: 'shopify-buy__quantity-container',
            quantityInput: 'shopify-buy__quantity',
            quantityButton: 'shopify-buy__btn--seamless',
            quantityIncrement: 'shopify-buy__quantity-increment',
            quantityDecrement: 'shopify-buy__quantity-decrement',
            buttonWithQuantity: 'shopify-buy__btn-and-quantity',
            quantityWithButtons: 'shopify-buy__quantity-with-btns',
            vertical: 'shopify-buy__layout-vertical',
            horizontal: 'shopify-buy__layout-horizontal'
          }
        },

        productSet: {
          styles: {
            products: {
              '@media (min-width: 601px)': {
                'margin-left': '-20px'
              }
            }
          },
          contents: {
            title: false,
            products: true,
            pagination: false
          },
          classes: {
            wrapper: 'shopify-buy__collection-wrapper',
            productSet: 'shopify-buy__collection',
            title: 'shopify-buy__collection__title',
            collection: 'shopify-buy__collection',
            products: 'shopify-buy__collection-products',
            product: 'shopify-buy__collection-product',
            paginationButton: 'shopify-buy__collection-pagination-button shopify-buy__btn'
          }
        },
        modalProduct: {
          contents: {
            img: false,
            imgWithCarousel: true,
            button: false,
            buttonWithQuantity: true
          },
          styles: {
            product: {
              '@media (min-width: 601px)': {
                'max-width': '100%',
                'margin-left': '0px',
                'margin-bottom': '0px'
              }
            },
            button: {
              'font-family': 'Montserrat, sans-serif',
              'font-weight': 'bold',
              ':hover': {
                'background-color': '#ff0000'
              },
              'background-color': '#d80000',
              ':focus': {
                'background-color': '#ff0000'
              }
            }
          },
          googleFonts: ['Montserrat'],
          text: {
            button: 'Add to cart'
          }
        },
        cart: {
          styles: {
            button: {
              'font-family': 'Montserrat, sans-serif',
              'font-weight': 'bold',
              ':hover': {
                'background-color': '#ff0000'
              },
              'background-color': '#d80000',
              ':focus': {
                'background-color': '#ff0000'
              }
            }
          },
          text: {
            total: 'Subtotal',
            button: 'Checkout'
          },
          googleFonts: ['Montserrat']
        },
        toggle: {
          styles: {
            toggle: {
              'font-family': 'Montserrat, sans-serif',
              'font-weight': 'bold',
              'background-color': '#d80000',
              ':hover': {
                'background-color': '#ff0000'
              },
              ':focus': {
                'background-color': '#ff0000'
              }
            }
          },
          googleFonts: ['Montserrat']
        }
      }
    })
  })
}
