import React from 'react'
import { Link } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import VerticalSplitRoundedIcon from '@material-ui/icons/VerticalSplitRounded'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'

import { isAdmin, useAuth } from 'utils/auth'
import { TargetIcon, FootPainIcon, ImproveCirculation, ImprovedBalance } from 'utils/icons'
import { START_NOW_PATH, SUCCESS_PATH } from 'consts/paths'
import styles from './HowItWorks.styles'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(styles)

function Page() {
  const classes = useStyles()
  const user = useAuth()

  return (
    <div>
      <div className={classes.page}>
        <Container component="main" maxWidth="lg" className={classes.root}>
          <Typography variant="h3" style={{ marginBottom: '15px' }}>
            HOW IT WORKS
          </Typography>
          <Typography variant="h1" className={classes.mainTitle}>
            3-Step Solution
          </Typography>
          <div className={classes.section}>
            <Button
              color="primary"
              size="large"
              variant="contained"
              component={Link}
              to={START_NOW_PATH}
              style={{ margin: '5px 0' }}
            >
              START NOW
            </Button>
          </div>
        </Container>
      </div>
      <Container maxWidth="lg" className={classes.heroBox}>
        <Grid container className={classes.heroGrid}>
          <Grid item lg={4} md={6} sm={12}>
            <img src="/img/howitworks-step1.jpg" />
            <Typography className={classes.stepPre}>STEP 1</Typography>
            <Typography variant="h2" className={classes.stepTitle}>
              LIGHT REJUVENATION
            </Typography>
            <Typography className={classes.stepDesc}>
              After decades of research, it was found that certain wavelengths of light within the red and infrared
              bands were very beneficial to living tissue. Light therapy has been in use by medical professionals to
              increase circulation and reduce pain and inflammation for more than twenty years. Our light device is
              optimized to increase circulation in your feet, which is critical to help give you relief and help your
              nerves heal. Simply use light therapy for twenty minutes per foot (or hand) once or twice per day.
            </Typography>
          </Grid>

          <Grid item lg={4} md={6} sm={12}>
            <img src="/img/howitworks-step2.jpg" />
            <Typography className={classes.stepPre}>STEP 2</Typography>
            <Typography variant="h2" className={classes.stepTitle}>
              BODY BOOSTER
            </Typography>
            <Typography className={classes.stepDesc}>
              The Neuragenix supplement was formulated to provide maximum support for nerve health and healing. Take one
              capsule twice per day on an empty stomach. It was designed with these goals in mind:
              <br />
              • To give your nerves the maximum nutritional support for healing and optimal nerve function. <br />
              • To support the health of your large and small blood vessels.
              <br />• To support optimal blood sugar levels
            </Typography>
          </Grid>

          <Grid item md={4} sm={12}>
            <img src="/img/howitworks-step3.jpg" />
            <Typography className={classes.stepPre}>STEP 3</Typography>
            <Typography variant="h2" className={classes.stepTitle}>
              FOUNTAIN OF YOUTH DIET
            </Typography>
            <Typography className={classes.stepDesc}>
              The single biggest factor that can magnify your results is what you DON'T eat. Eating the wrong foods will
              increase inflammation and damage the small blood vessels in your your feet. This can lead to more pain,
              numbness, cramping and other health issues. The FOY (Fountain of Youth) Diet is designed to revive your
              feet and health by lowering inflammation in your body. The main foods that cause inflammation are foods
              made from grains, sugar and most dairy products.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Page
