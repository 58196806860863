import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner'
import { Field } from 'redux-form'
import TextField from 'components/FormTextField'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import ContactForm from './components/ContactForm'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { FootDocLogo } from 'utils/icons'

import styles from './Contact.styles'
import { Grid } from '@material-ui/core'
import { MAIL_CONTACT } from 'consts/functions'
import { callF } from 'utils/serverless'
import { useNotifications } from 'modules/notification'

const useStyles = makeStyles(styles)

function Page() {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)
  const [mailSent, setMailSent] = useState(false)

  const { showSuccess, showError } = useNotifications()

  const onSubmit = (data) => {
    setSubmitting(true)
    callF(
      MAIL_CONTACT,
      data,
      () => {
        setSubmitting(false)
        setMailSent(true)
      },
      (err) => {
        console.error(err)
        console.log(data)
        setSubmitting(false)
        showError("Couldn't send your contact! Please try again later.")
      }
    )
  }
  const onSubmitFail = () => {}

  return (
    <div>
      <div className={classes.page}>
        <Container component="main" maxWidth="lg" className={classes.root}>
          <Typography variant="h3" style={{ marginBottom: '15px' }}>
            CONTACT
          </Typography>
          <Typography variant="h1" className={classes.mainTitle}>
            Have a Question? Get in Touch.
          </Typography>
        </Container>
      </div>
      <Container maxWidth="lg" className={classes.heroBox}>
        <Grid container spacing={4} className={classes.heroGrid}>
          <Grid item sm={12} md={4} style={{ textAlign: 'left' }}>
            <Typography className={classes.heroTitle}>Customer Support</Typography>
            <Typography className={classes.heroPhone}>1.555.896.2432</Typography>
            <Typography variant="h2" style={{ marginBottom: '55px' }}>
              Monday - Friday <br />
              9:00am - 5:00pm PST
            </Typography>
            <FootDocLogo />
          </Grid>
          <Grid item sm={12} md={8} style={{ textAlign: 'left' }}>
            {mailSent ? (
              <Typography variant="h2">Your message has been sent!</Typography>
            ) : (
              <ContactForm onSubmit={onSubmit} onSubmitFail={onSubmitFail} sending={submitting} />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Page
