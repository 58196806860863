import React from 'react'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { HOME_PATH, LOGIN_PATH, START_NOW_PATH, HOW_WORKS_PATH, SUCCESS_PATH, CONTACT_PATH } from 'consts/paths'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { Container, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  testItem: {
    ...theme.flexColumnCenter,
    flexDirection: 'column-reverse',
    width: '100%',
    height: '273px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  testBox: {
    ...theme.flexColumnCenter,
    backgroundColor: 'white',
    padding: '26px 10px 0 10px',
    width: '273px'
  },
  testText: {
    ...theme.flexColumnCenter,
    alignItems: 'normal',
    backgroundColor: 'white',
    minHeight: '273px',
    padding: '35px',
    justifyContent: 'center'
  },
  testChildren: {
    textAlign: 'center',
    fontSize: '20px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '30px'
    },
    fontStyle: 'italic'
  },
  testName: {
    textAlign: 'right'
  }
}))

function Testimonial({ video, img, name, children }) {
  const classes = useStyles()

  let sm = 12
  let md = 12
  let body
  if (video) {
    sm = 6
    md = 4
    body = (
      <div style={{ backgroundImage: `url(${img})` }} className={classes.testItem}>
        <div className={classes.testBox}>
          {name}
          <Typography color="primary" component={Link} to={video}>
            watch
          </Typography>
        </div>
      </div>
    )
  } else {
    body = (
      <div className={classes.testText}>
        <Typography className={classes.testChildren}>&ldquo;{children}&rdquo;</Typography>
        <Typography className={classes.testName}>{name}</Typography>
      </div>
    )
  }

  return (
    <Grid item xs={12} sm={sm} md={md} style={{ padding: '10px' }}>
      {body}
    </Grid>
  )
}

export default Testimonial
