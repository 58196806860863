export default (theme) => ({
  flex: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: '16px',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      marginTop: '4px'
    }
  },
  startBar: {
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  middleBar: {
    flexGrow: 1,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  endBar: {
    width: '200px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexGrow: 1
    }
  },
  menuLink: {
    margin: '0 15px',
    '&:hover': {
      color: '#FAA'
    }
  },
  menuLinkMob: {
    color: 'white'
  },
  signIn: {
    color: 'white',
    textDecoration: 'none',
    alignSelf: 'center',
    boxSizing: 'border-box',
    height: '45px',
    width: '144px',
    border: '2px solid #FFFFFF'
  },
  brand: {
    display: 'flex',
    alignItems: 'center'
  },
  brandImg: {
    maxHeight: '54px',
    marginRight: '10px'
  },
  mobileMenu: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  mobileDrawer: {
    '& .MuiDrawer-paper': {
      backgroundColor: 'rgba(0,0,0,0.8)'
    }
  }
})
