export default (theme) => ({
  page: {
    ...theme.page,
    backgroundImage: "linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.5)), url('/img/bg-success.jpg')",
    backgroundPositionY: 'center',
    backgroundPositionX: 'center',
    minHeight: '606px',
    paddingTop: '60px'
  },
  root: {
    ...theme.mainContainer,
    color: 'white'
  },
  section: {
    ...theme.flexColumnCenter,
    margin: '25px auto'
  },
  mainTitle: {
    ...theme.mainTitle
  },
  heroBox: {
    ...theme.heroBox,
    ...theme.flexRowCenter,
    padding: '40px 15px',
    marginBottom: '100px'
  }
})
