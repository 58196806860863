import theme from 'theme'

export default () => ({
  flex: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: '#120D0E',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '300px',
    paddingTop: '16px',
    boxShadow: 'none'
  },
  footerGrid: {
    ...theme.flexRowCenter
  },
  footerItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    flexGrow: 1
  },
  menuLink: {
    color: 'white',
    margin: '8px',
    '&:hover': {
      color: '#FAA'
    }
  },
  signIn: {
    color: 'white',
    textDecoration: 'none',
    boxSizing: 'border-box',
    height: '45px',
    width: '144px',
    border: '2px solid ' + theme.colors.red
  },
  brand: {
    display: 'flex',
    alignItems: 'center'
  },
  brandImg: {
    maxHeight: '54px',
    marginRight: '10px'
  },
  socialLink: {
    margin: '8px'
  },
  text: {
    margin: '8px',
    color: theme.colors.gray
  }
})
