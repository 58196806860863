import { Loadable } from 'utils/components'
import { NOT_MEMBER_PATH as path } from 'consts/paths'

export default {
  path,
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: 'Forms' */ './components/NotMemberPage')
  })
}
