import React from 'react'
import PropTypes from 'prop-types'
import Navbar from 'containers/Navbar'
import { Notifications } from 'modules/notification'
import { makeStyles } from '@material-ui/core/styles'
import styles from './CoreLayout.styles'
import Box from '@material-ui/core/Box'
import Copyright from 'components/Copyright'
import Footer from 'containers/Footer'

const useStyles = makeStyles(styles)

function CoreLayout({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.container} id="mainApp">
      <Navbar />
      <>{children}</>
      <Notifications />
      <Footer />
    </div>
  )
}

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default CoreLayout
