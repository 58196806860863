export const HOME_PATH = '/'
export const PORTAL_PATH = '/portal'
export const ACCOUNT_PATH = '/account'
export const LOGIN_PATH = '/login'
export const SIGNUP_PATH = '/signup'
export const START_NOW_PATH = '/start'
export const HOW_WORKS_PATH = '/how'
export const SUCCESS_PATH = '/success'
export const CONTACT_PATH = '/contact'
export const USERS_PATH = '/users'
export const CMS_PATH = '/cms'
export const NOT_MEMBER_PATH = '/notmember'

// to remote
export const MAGIC_PATH = '/xoxoxo'
export const CARE_PLAN_CALCULATOR = '/xoxoxo2'
