import React from 'react'
import { Link } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Testimonial from 'components/Testimonial'

import { START_NOW_PATH, SUCCESS_PATH } from 'consts/paths'
import styles from './SuccessStories.styles'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(styles)

function Page() {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.page}>
        <Container component="main" maxWidth="lg" className={classes.root}>
          <Typography variant="h3" style={{ marginBottom: '15px' }}>
            SUCCESS STORIES
          </Typography>
          <Typography variant="h1" className={classes.mainTitle}>
            Real People With Real Results
          </Typography>
          <div className={classes.section}>
            <Button
              color="primary"
              size="large"
              variant="contained"
              component={Link}
              to={START_NOW_PATH}
              style={{ margin: '5px 0' }}
            >
              START NOW
            </Button>
          </div>
        </Container>
      </div>
      <Container maxWidth="lg" className={classes.heroBox}>
        <Grid container className={classes.heroGrid}>
          <Testimonial name="Renee M">
            Wow..ty sooo much..
            <br />
            <br />I suffer from PN..Fibromyalgia..Ataxia..I have severe balance issues..too many to list..over 11 yrs
            now..I also have chronic knee problems..I've seen sooo many drs to count..no help..I've used
            meds..topicals..minimal help..I purchased LightSource through a dr..was expensive so I made payments..it was
            thee only thing that rejuvenated my nerves..I use on feet n knees..I almost purchased 2 at the time..wish I
            had..wen I travel it come with me in the convenient tube..it's great..n it's the only thing that helps..dr
            advised to use twice a day..can I use it more often?? Is it affordable to buy a second one..you make a great
            product..I'm in a few support groups n WER always trying to help one another ..ty again for your help..I
            love it..now I have hope again..
          </Testimonial>
          <Testimonial name="Julie H">
            After just one 20 minute session my pain has dramatically decreased. I'm looking forward to continued
            healing with this product.
          </Testimonial>
          <Testimonial>
            After 2 weeks of use I slept through the night last night! I have had countless nights where I don't only
            have trouble getting to sleep I wake up 3-4 times. I feel energized and peppy! Thank you
          </Testimonial>
          {/* <Testimonial img="/img/test-serena.jpg" video="/video/serena.mpg" name="SERENA BECKER" />
          <Testimonial img="/img/test-jonathan.jpg" video="/video/jonathan.mpg" name="JONATHAN CONNELLY" />
          <Testimonial img="/img/test-ava.jpg" video="/video/ava.mpg" name="AVA PATTERSON" /> */}
        </Grid>
      </Container>
    </div>
  )
}

export default Page
