// Forms actions
export const CALCULATE_FORM_ONE = 'calculateFormOne'
export const CALCULATE_FORM_ONE_VERSION = 2

export const CALCULATE_FORM_TWO = 'calculateFormTwo'
export const CALCULATE_FORM_TWO_VERSION = 1

// Users management
export const LIST_USERS = 'listAllUsers'
export const SET_USER_ADMIN = 'setUserAdmin'
export const SET_USER_CAREMEMBER = 'setUserCareMember'
export const SET_USER_MEMBER = 'setUserMember'
export const DELETE_USER = 'deleteUser'

export const MAIL_CONTACT = 'mailContact'
