import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore' // make sure you add this for firestore
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import { Provider } from 'react-redux'
import ThemeSettings from 'theme'
import { SnackbarProvider } from 'notistack'
import { firebase as fbConfig, reduxFirebase as rfConfig, env } from 'config'

const theme = createMuiTheme(ThemeSettings)

// Initialize Firebase instance
firebase.initializeApp(fbConfig)
if (env === 'local') {
  firebase.functions().useEmulator('localhost', 5001)
  window.firebase = firebase
  rfConfig.enableLogging = true
}
firebase.firestore().settings({ ignoreUndefinedProperties: true })

function App({ routes, store }) {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <SnackbarProvider>
          <ReactReduxFirebaseProvider
            firebase={firebase}
            config={rfConfig}
            dispatch={store.dispatch}
            createFirestoreInstance={createFirestoreInstance}
          >
            <Router>{routes}</Router>
          </ReactReduxFirebaseProvider>
        </SnackbarProvider>
      </Provider>
    </MuiThemeProvider>
  )
}

App.propTypes = {
  routes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
}

export default App
