export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%'
  },
  page: {
    ...theme.accountPage,
    width: '100%',
    height: '100%',
    minHeight: '600px',
    paddingTop: '200px'
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50%',
    marginTop: '7rem'
  }
})
