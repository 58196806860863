import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import VerticalSplitRoundedIcon from '@material-ui/icons/VerticalSplitRounded'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'

import { isAdmin, useAuth } from 'utils/auth'
import { TargetIcon, FootPainIcon, ImproveCirculation, ImprovedBalance } from 'utils/icons'
import { LOGIN_PATH, SUCCESS_PATH } from 'consts/paths'
import styles from './StartNow.styles'
import { Grid } from '@material-ui/core'
import shopify from './shopify'

const useStyles = makeStyles(styles)

const DOCS = [
  {
    file: 'Research Infrared lights Case study - Euro Journal.pdf',
    name: 'Research Infrared lights Case study - Euro Journal'
  },
  {
    file: 'Research -lights - Effectiveness of MIRE in PN - Vokert - Hassan.pdf',
    name: 'Research Lights - Effectiveness of MIRE in PN'
  },
  {
    file: 'Research Lights Improved foot sensitivity and pain reduction- Journal of D and its complications.pdf',
    name: 'Research Lights Improved foot sensitivity and pain reduction - Journal of D and its complications'
  },
  {
    file: 'Research Lights Improved sensitivity in patients with PN research.pdf',
    name: 'Research Lights Improved sensitivity in patients with PN research'
  },
  {
    file: 'Research Lights Reversal of Diabetic Peripheral Neuropathy and new wounds - powell- carnegie.pdf',
    name: 'Research Lights Reversal of Diabetic Peripheral Neuropathy and new wounds'
  },
  {
    file: 'Research Lights Reversal of DPN- decrease in falls research- Age and Ageing Advance Access.pdf',
    name: 'Research Lights Reversal of DPN - decrease in falls research - Age and Ageing Advance Access'
  },
  {
    file: 'Research Lights Review of current research- European Journal of Academic Essays.pdf',
    name: 'Research Lights Review of current research - European Journal of Academic Essays'
  },
  {
    file: 'Research lights - Symptomatic Reversal of PN - Kochman- Carnegie.pdf',
    name: 'Research lights - Symptomatic Reversal of PN'
  }
]

function Page() {
  const classes = useStyles()
  const user = useAuth()

  useEffect(() => {
    shopify()
  }, [])

  return (
    <div>
      <div className={classes.page}>
        <Container component="main" maxWidth="lg" className={classes.root}>
          <Typography variant="h3" style={{ marginBottom: '15px' }}>
            START NOW
          </Typography>
          <Typography variant="h1" className={classes.mainTitle}>
            Start the Path to Foot & Hand Wellness
          </Typography>
        </Container>
      </div>
      <Container maxWidth="lg" className={classes.heroBox}>
        <div className={classes.shopify}>
          <div id="collection-component-1617768202631"></div>
        </div>
      </Container>

      <Container maxWidth="lg" className={classes.heroBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ marginBottom: '15px' }}>
              Research on Light Therapy and Nutrition
            </Typography>
          </Grid>

          {DOCS.map((d) => (
            <Grid key={d.file} item xs={12} className={classes.fileBox}>
              <Typography color="primary">
                <a href={`/pdfs/${d.file}`} target="_blank" rel="noreferrer">
                  {d.name}
                </a>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  )
}

export default Page
