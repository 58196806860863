export const ACCOUNT_FORM_NAME = 'account'
export const LOGIN_FORM_NAME = 'login'
export const SIGNUP_FORM_NAME = 'signup'
export const CONTACT_FORM = 'contact'
export const CMS_FORM = 'cms'

export const CURRENCY = 'currency'
export const PERCENTAGE = 'percentage'
export const NUMBER = 'number'

export const FIRESTORE_FORM_ONE = 'forms_one'
export const FIRESTORE_FORM_TWO = 'forms_two'
export const FIRESTORE_FORM_CARE_PLAN = 'forms_careplan'
export const FIRESTORE_CARE_PLAN = 'care_plan'
