import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useFirebase, isLoaded } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import { UserIcon } from 'utils/auth'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'
import { ACCOUNT_PATH, USERS_PATH, PORTAL_PATH, CMS_PATH } from 'consts/paths'
import { isAdmin, getMember } from 'utils/auth'
import { useSelector } from 'react-redux'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'

const useStyles = makeStyles(() => ({
  buttonRoot: {
    color: 'white'
  }
}))

function AccountMenu() {
  const classes = useStyles()
  const [anchorEl, setMenu] = useState(null)
  const history = useHistory()
  const firebase = useFirebase()
  const [auth, profile] = useSelector(({ firebase: { auth, profile } }) => [auth, profile])
  const name = isLoaded(profile) && isLoaded(auth) && (profile.displayName || auth.displayName)
  const admin = isAdmin(auth, profile)

  function closeAccountMenu(e) {
    setMenu(null)
  }
  function handleMenu(e) {
    setMenu(e.target)
  }
  function handleLogout() {
    return firebase.logout().then(() => {
      closeAccountMenu()
      history.push('/')
    })
  }

  function goToPortal() {
    closeAccountMenu()
    history.push(PORTAL_PATH)
  }

  function goToAccount() {
    closeAccountMenu()
    history.push(ACCOUNT_PATH)
  }

  function goToManageUsers() {
    closeAccountMenu()
    history.push(USERS_PATH)
  }

  function goToManageContent() {
    closeAccountMenu()
    history.push(CMS_PATH)
  }

  return (
    <Fragment>
      {`${name || ''} ${admin ? '(Admin)' : ''}`}
      <IconButton
        aria-owns={anchorEl ? 'menu-appbar' : null}
        aria-haspopup="true"
        onClick={handleMenu}
        classes={{ root: classes.buttonRoot }}
      >
        <UserIcon photoURL={profile.photoURL || auth.photoURL} />
      </IconButton>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={closeAccountMenu}>
                <MenuList autoFocusItem={anchorEl} id="menu-list-grow">
                  {admin && <MenuItem onClick={goToManageUsers}>Manage Users</MenuItem>}
                  {admin && <MenuItem onClick={goToManageContent}>Manage Content</MenuItem>}
                  <MenuItem onClick={goToPortal}>Portal</MenuItem>
                  <MenuItem onClick={goToAccount}>Account</MenuItem>
                  <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}

AccountMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired // from enhancer (withRouter)
  }),
  firebase: PropTypes.shape({
    logout: PropTypes.func.isRequired // from enhancer (withFirebase)
  })
}

export default AccountMenu
