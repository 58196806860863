import firebase from 'firebase'

export const callF = (fn, data, callback, errorCallback) => {
  const { currentUser } = firebase.auth()
  currentUser.getIdToken().then((idToken) => {
    firebase
      .functions()
      .httpsCallable(fn)({
        idToken,
        ...data
      })
      .then(callback)
      .catch((err) => {
        console.error(err)
        errorCallback && errorCallback(err)
      })
  })
}
