import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import {
  HOME_PATH,
  LOGIN_PATH,
  START_NOW_PATH,
  HOW_WORKS_PATH,
  SUCCESS_PATH,
  CONTACT_PATH,
  PORTAL_PATH
} from 'consts/paths'
import AccountMenu from './AccountMenu'
import styles from './Navbar.styles'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

const useStyles = makeStyles(styles)

function Navbar() {
  const classes = useStyles()
  const location = useLocation()
  const [mobileDrawer, setMobileDrawer] = useState(false)
  const onMobileOpen = () => setMobileDrawer(true)
  const onMobileClose = () => setMobileDrawer(false)

  const NavLink = ({ children, to }) => (
    <Typography
      color={location.pathname === to ? 'primary' : 'inherit'}
      className={classes.menuLink}
      component={Link}
      to={to || ''}
      key={to}
    >
      {children}
    </Typography>
  )

  // Get auth from redux state
  const auth = useSelector((state) => state.firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)

  const Menu = [
    { label: 'START NOW', path: START_NOW_PATH },
    { label: 'HOW IT WORKS', path: HOW_WORKS_PATH },
    { label: 'SUCCESS STORIES', path: SUCCESS_PATH },
    { label: 'CONTACT', path: CONTACT_PATH }
  ]

  if (authExists) Menu.push({ label: 'PORTAL', path: PORTAL_PATH })

  return (
    <AppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.appBar}>
        <div className={classes.mobileMenu}>
          <div className={classes.iconContainer}>
            <IconButton onClick={onMobileOpen} className={classes.iconButton} color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.mobileDrawer}
            anchor="left"
            open={mobileDrawer}
            onClose={onMobileClose}
            onOpen={onMobileOpen}
          >
            <AppBar title="Menu" />
            <List>
              <ListItem key={HOME_PATH}>
                <Typography
                  color="inherit"
                  component={Link}
                  to={HOME_PATH}
                  className={classes.brand}
                  style={{ marginBottom: '20px' }}
                  data-test="brand"
                >
                  <img src="/logo.svg" className={classes.brandImg} alt="FOOT DOC in the Box" />
                </Typography>
              </ListItem>
              {Menu.map((item, index) => (
                <ListItem component={Link} to={item.path} button key={item.label}>
                  <ListItemText primary={item.label} className={classes.menuLinkMob} />
                </ListItem>
              ))}
            </List>
          </SwipeableDrawer>
        </div>

        <div className={classes.startBar}>
          {location.pathname !== HOME_PATH && (
            <Typography color="inherit" component={Link} to={HOME_PATH} className={classes.brand} data-test="brand">
              <img src="/logo.svg" className={classes.brandImg} alt="FOOT DOC in the Box" />
            </Typography>
          )}
        </div>

        <div className={classes.middleBar}>
          {Menu.map((m) => (
            <NavLink to={m.path} key={m.path}>
              {m.label}
            </NavLink>
          ))}
        </div>
        <div className={classes.endBar}>
          {authExists ? (
            <AccountMenu />
          ) : (
            <Button className={classes.signIn} component={Link} to={LOGIN_PATH}>
              CLIENT LOGIN
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
