import { Loadable } from 'utils/components'
import { PORTAL_PATH as path } from 'consts/paths'

export default {
  path,
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: 'Portal' */ './components/PortalPage')
  })
}
