export const TargetIcon = () => (
  <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.621 28.1238C32.021 28.1238 31.435 27.8228 31.097 27.2768C30.573 26.4358 30.831 25.3298 31.673 24.8078C37.876 20.9498 45.657 20.8348 51.977 24.5058C52.834 25.0038 53.125 26.1008 52.627 26.9578C52.131 27.8138 51.033 28.1068 50.175 27.6078C45.006 24.6038 38.641 24.7008 33.566 27.8528C33.271 28.0368 32.944 28.1238 32.621 28.1238Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.0752 61.3064C38.6212 61.3064 35.1702 60.4044 32.0972 58.6024C31.2432 58.1014 30.9572 57.0014 31.4592 56.1484C31.9602 55.2934 33.0592 55.0074 33.9132 55.5084C38.8872 58.4274 45.0872 58.4594 50.0942 55.5904C50.9532 55.0984 52.0482 55.3954 52.5422 56.2554C53.0342 57.1154 52.7372 58.2114 51.8772 58.7034C48.8482 60.4394 45.4612 61.3064 42.0752 61.3064Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.3184 17.4812C25.8694 17.4812 25.4294 17.2552 25.1744 16.8462C24.7834 16.2142 24.9774 15.3852 25.6074 14.9932C35.4554 8.8742 47.8014 8.6912 57.8314 14.5162C58.4734 14.8892 58.6914 15.7122 58.3184 16.3562C57.9444 16.9982 57.1214 17.2152 56.4794 16.8422C47.3144 11.5192 36.0284 11.6852 27.0284 17.2792C26.8064 17.4162 26.5614 17.4812 26.3184 17.4812Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.1173 72.9217C36.6363 72.9217 31.1573 71.4907 26.2843 68.6307C25.6423 68.2537 25.4293 67.4297 25.8043 66.7897C26.1803 66.1477 27.0063 65.9357 27.6443 66.3097C36.4663 71.4857 47.4593 71.5427 56.3353 66.4567C56.9803 66.0877 57.8023 66.3107 58.1713 66.9547C58.5403 67.5997 58.3173 68.4217 57.6733 68.7917C52.8653 71.5457 47.4903 72.9217 42.1173 72.9217Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6924 7.93143C20.3934 7.93143 20.0994 7.78243 19.9294 7.50843C19.6694 7.08743 19.7974 6.53543 20.2174 6.27343C33.3024 -1.85757 49.7094 -2.10057 63.0334 5.63843C63.4624 5.88743 63.6084 6.43543 63.3584 6.86443C63.1094 7.29243 62.5614 7.43743 62.1324 7.18943C49.3794 -0.216574 33.6824 0.0164256 21.1664 7.79643C21.0174 7.88743 20.8534 7.93143 20.6924 7.93143Z"
      fill="white"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="20" y="75" width="44" height="9">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.6741 75.8724H63.2736V83.2448H20.6741V75.8724Z" fill="white" />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.1543 83.2449C34.8713 83.2449 27.5913 81.3429 21.1173 77.5429C20.6893 77.2919 20.5473 76.7419 20.7973 76.3149C21.0493 75.8889 21.5963 75.7449 22.0243 75.9959C34.2943 83.1959 49.5853 83.2729 61.9303 76.2009C62.3593 75.9559 62.9083 76.1019 63.1543 76.5329C63.4013 76.9629 63.2523 77.5109 62.8223 77.7569C56.4363 81.4159 49.2933 83.2449 42.1543 83.2449Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.3329 52.449C25.7329 52.449 25.1469 52.149 24.8079 51.602C20.9519 45.397 20.8369 37.616 24.5069 31.297C25.0049 30.441 26.1029 30.149 26.9589 30.647C27.8149 31.144 28.1059 32.242 27.6089 33.099C24.6059 38.268 24.6999 44.634 27.8549 49.709C28.3769 50.551 28.1189 51.657 27.2779 52.179C26.9819 52.363 26.6559 52.449 26.3329 52.449Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.0546 52.0623C56.7456 52.0623 56.4336 51.9823 56.1496 51.8153C55.2936 51.3143 55.0076 50.2153 55.5096 49.3613C58.4286 44.3863 58.4596 38.1853 55.5916 33.1803C55.0996 32.3213 55.3966 31.2253 56.2566 30.7323C57.1166 30.2403 58.2116 30.5383 58.7036 31.3973C62.2106 37.5153 62.1716 45.0943 58.6026 51.1763C58.2696 51.7453 57.6696 52.0623 57.0546 52.0623Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1377 58.3015C15.6887 58.3015 15.2487 58.0765 14.9937 57.6665C8.87371 47.8185 8.69271 35.4715 14.5177 25.4435C14.8907 24.8005 15.7137 24.5835 16.3567 24.9555C16.9987 25.3285 17.2167 26.1525 16.8437 26.7945C11.5187 35.9605 11.6867 47.2455 17.2807 56.2455C17.6707 56.8775 17.4777 57.7065 16.8477 58.0995C16.6257 58.2365 16.3807 58.3015 16.1377 58.3015Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.4706 57.6551C67.2386 57.6551 67.0036 57.5951 66.7906 57.4701C66.1496 57.0931 65.9346 56.2691 66.3106 55.6301C71.4876 46.8081 71.5436 35.8151 66.4576 26.9401C66.0886 26.2961 66.3116 25.4731 66.9556 25.1041C67.6016 24.7361 68.4236 24.9581 68.7926 25.6021C74.3566 35.3131 74.2936 47.3411 68.6316 56.9901C68.3806 57.4181 67.9316 57.6551 67.4706 57.6551Z"
      fill="white"
    />
    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="19" width="8" height="45">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 19.7937H7.93263V63.4793H0V19.7937Z" fill="white" />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.03706 63.4793C6.73706 63.4793 6.44306 63.3303 6.27406 63.0563C-1.85794 49.9713 -2.10194 33.5663 5.64006 20.2413C5.88906 19.8103 6.43706 19.6663 6.86506 19.9163C7.29306 20.1653 7.43906 20.7123 7.18906 21.1413C-0.216942 33.8913 0.0160584 49.5893 7.79806 62.1083C8.05806 62.5303 7.93006 63.0833 7.51006 63.3443C7.36106 63.4353 7.19806 63.4793 7.03706 63.4793Z"
      fill="white"
    />
    <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="75" y="20" width="9" height="43">
      <path fillRule="evenodd" clipRule="evenodd" d="M75.8735 20.0011H83.245V62.6014H75.8735V20.0011Z" fill="white" />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.7695 62.6014C76.6155 62.6014 76.4585 62.5624 76.3165 62.4784C75.8885 62.2274 75.7465 61.6784 75.9975 61.2504C83.1965 48.9814 83.2755 33.6894 76.2025 21.3434C75.9555 20.9134 76.1035 20.3664 76.5345 20.1204C76.9605 19.8734 77.5105 20.0224 77.7565 20.4524C85.1505 33.3554 85.0685 49.3344 77.5435 62.1594C77.3765 62.4434 77.0775 62.6014 76.7695 62.6014Z"
      fill="white"
    />
  </svg>
)

export const FootPainIcon = () => (
  <svg width="85" height="83" viewBox="0 0 85 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.2624 66.3432C35.0325 64.7066 33.9006 62.6 33.9006 60.0842C33.9006 57.3759 35.225 54.8846 37.4433 53.4205L39.2966 56.2303C38.025 57.0686 37.2659 58.5099 37.2659 60.0842C37.2659 61.5269 37.8787 62.6213 39.2539 63.6286L37.2624 66.3432Z"
      fill="white"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="47" y="0" width="33" height="83">
      <path fillRule="evenodd" clipRule="evenodd" d="M47.3616 0.350037H79.333V82.8019H47.3616V0.350037Z" fill="white" />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.4436 82.8019V79.4367C74.8355 79.4367 75.9674 78.3044 75.9674 76.9125C75.9674 75.6752 75.0851 74.6301 73.8709 74.4247L68.3168 73.4863C65.5378 72.9522 63.2288 71.5571 60.9972 70.2082C59.5073 69.3077 58.1006 68.4565 56.638 67.9028C47.3616 64.4189 47.3616 56.6744 47.3616 52.5133V0.349976H50.7272V52.5133C50.7272 56.7794 50.7272 62.0886 57.8262 64.7524C59.5811 65.4181 61.1862 66.389 62.7378 67.3274C64.808 68.5797 66.7638 69.7609 68.9149 70.1736L74.4309 71.1053C77.2708 71.5851 79.333 74.0271 79.333 76.9125C79.333 80.1598 76.6905 82.8019 73.4436 82.8019"
      fill="white"
    />
    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="18" y="0" width="17" height="83">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7561 0.350037H34.8733V82.8019H18.7561V0.350037Z"
        fill="white"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.8733 82.8019C27.9419 82.8019 23.8042 79.0275 23.8042 72.7058C23.8042 68.6833 24.8461 65.3555 25.7663 62.419C26.5188 60.0166 27.1694 57.9428 27.1694 55.8789V35.6867C27.1694 30.9869 25.6823 27.6608 23.9585 23.8091C21.6415 18.6245 18.7561 12.1733 18.7561 0.349976H22.1214C22.1214 11.4551 24.7296 17.2886 27.0315 22.4353C28.8326 26.4631 30.535 30.2687 30.535 35.6867V55.8789C30.535 58.457 29.779 60.8709 28.9789 63.4263C28.0882 66.2627 27.1694 69.1974 27.1694 72.7058C27.1694 77.1722 29.7612 79.4367 34.8733 79.4367V82.8019Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.5542 43.4856L57.2117 38.1943L58.7429 35.1969L64.1889 37.9839V31.2531L74.5318 36.5444L73.0002 39.5418L67.5542 36.7547V43.4856Z"
      fill="white"
    />
    <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="31" width="18" height="13">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 31.2531H17.3201V43.4856H0V31.2531Z" fill="white" />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3425 43.4856L0 38.1943L1.5316 35.1969L6.97725 37.9839V31.2531L17.3201 36.5444L15.7885 39.5418L10.3425 36.7547V43.4856Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.3726 22.6902L56.9929 20.3109L65.653 11.6508L68.0323 14.0301L59.3726 22.6902Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.6479 59.7175L57.0029 51.0725L59.3826 48.6929L68.0276 57.3382L65.6479 59.7175Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1803 22.7115L6.50732 14.0385L8.88697 11.6589L17.56 20.3322L15.1803 22.7115Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.89151 59.7093L6.51221 57.33L15.1772 48.665L17.5565 51.0443L8.89151 59.7093Z"
      fill="white"
    />
    <mask id="mask3" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="85" height="83">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 82.802H84.3811V0.350037H0V82.802Z" fill="white" />
    </mask>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.9292 82.802H5.29445V79.4368H1.9292V82.802Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.66016 82.802H84.3813V79.4368H8.66016V82.802Z" fill="white" />
  </svg>
)

export const ImproveCirculation = () => (
  <svg width="86" height="88" viewBox="0 0 86 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.9133 10.0183L35.1288 6.93878C19.822 10.1679 7.91605 22.7071 5.62955 38.3335L0 38.3318L6.7762 48.4366L14.2944 38.3335H8.83915C11.1002 24.1921 21.9852 12.899 35.9133 10.0183Z"
      fill="white"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="51" width="43" height="37">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.89893 51.162H47.3951V87.077H5.89893V51.162Z" fill="white" />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.97593 51.162L5.89893 51.9508C9.12808 67.2516 21.663 79.1593 37.292 81.4458V87.077L47.3951 80.3059L37.292 72.7817V78.2379C23.1497 75.9743 11.8566 65.0892 8.97593 51.162Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.6909 77.9088L50.478 80.9883C65.7814 77.7617 77.6908 65.2225 79.9747 49.5936H85.6026L78.8323 39.493L71.3107 49.5936H76.7626C74.4999 63.7367 63.6207 75.0298 49.6909 77.9088Z"
      fill="white"
    />
    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="38" y="0" width="42" height="37">
      <path fillRule="evenodd" clipRule="evenodd" d="M38.2104 0.850403H79.704V36.7676H38.2104V0.850403Z" fill="white" />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.627 36.7676L79.704 35.9796C76.4774 20.6754 63.9417 8.76943 48.3127 6.48378V0.849976L38.2104 7.62703L48.3127 15.1478V9.69338C62.455 11.9552 73.7472 22.8403 76.627 36.7676Z"
      fill="white"
    />
  </svg>
)

export const ImprovedBalance = () => (
  <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.1407 9.28C51.1407 12.2957 48.6964 14.74 45.6807 14.74C42.665 14.74 40.2207 12.2957 40.2207 9.28V7.46C40.2207 4.44426 42.665 2 45.6807 2C48.6964 2 51.1407 4.44426 51.1407 7.46V9.28Z"
      stroke="white"
      strokeWidth="3"
    />
    <path
      d="M44.5043 58.4271L63.88 62.0598V78.4398C63.88 82.4602 67.1414 85.7198 71.16 85.7198V61.1935C71.16 57.346 68.743 53.9153 65.1212 52.6231L51.0781 47.6072C53.6771 41.5594 54.78 35.5315 54.78 29.2998H78.44C82.4622 29.2998 85.72 26.042 85.72 22.0198H2C2 26.042 5.25962 29.2998 9.28 29.2998H36.58V36.5252C36.58 46.972 32.1046 56.9092 24.2586 63.8252L10.5558 75.91C7.733 78.4053 7.18882 82.5894 9.28 85.7198L35.8702 66.5753C41.0936 62.806 45.3724 58.0376 48.5374 52.6504"
      stroke="white"
      strokeWidth="3"
    />
    <path d="M46.5181 46.2435L51.1227 47.4993H51.1409" stroke="white" strokeWidth="3" />
    <path d="M42.9687 45.2801L35.9253 43.3491" stroke="white" strokeWidth="3" />
  </svg>
)

export const FootDocLogo = () => (
  <svg width="231" height="257" viewBox="0 0 231 257" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.453 39.7546C176.085 41.1382 181.128 36.5294 183.595 29.4955C186.066 22.4379 185.208 15.6207 181.565 14.2392C177.901 12.8721 172.937 17.5056 170.43 24.5395C167.887 31.6145 168.842 38.3803 172.453 39.7546Z"
      fill="#E5E6E8"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="139" y="0" width="23" height="36">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.136 0.959656H161.187V35.0052H139.136V0.959656Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.583 34.7853C151.105 36.2922 157.631 29.8873 160.173 20.626C162.709 11.3709 160.333 2.661 154.8 1.17574C149.224 -0.311573 142.686 6.02751 140.161 15.3546C137.581 24.6169 140.045 33.3371 145.583 34.7853Z"
        fill="#E5E6E8"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M205.964 58.8081C209.118 59.9315 213.52 56.0079 215.734 50.0272C217.928 44.0771 217.217 38.3482 214.014 37.1922C210.834 36.0688 206.498 39.9843 204.292 45.9619C202.041 51.9273 202.814 57.6613 205.964 58.8081Z"
      fill="#E5E6E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189.626 48.8271C192.6 49.9493 197.012 45.4898 199.447 38.9417C201.881 32.4352 201.396 26.2696 198.398 25.1717C195.432 24.0566 190.983 28.4928 188.566 34.9993C186.118 41.5666 186.592 47.7281 189.626 48.8271Z"
      fill="#E5E6E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M229.386 51.1871C227.083 50.1645 223.326 53.434 220.846 58.5335C218.476 63.7005 218.374 68.7357 220.62 69.8069C222.9 70.8538 226.694 67.5389 229.111 62.4225C231.536 57.2723 231.624 52.233 229.386 51.1871Z"
      fill="#E5E6E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.5472 39.7546C63.1577 38.3803 64.1135 31.6145 61.5699 24.5395C59.0583 17.5056 54.0994 12.8721 50.4357 14.2392C46.7932 15.6207 45.9326 22.4379 48.4051 29.4955C50.8735 36.5294 55.9156 41.1382 59.5472 39.7546Z"
      fill="#E5E6E8"
    />
    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="70" y="0" width="23" height="36">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.0459 0.959656H92.0978V35.0052H70.0459V0.959656Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.6527 34.7853C91.1831 33.3371 93.6514 24.6169 91.0754 15.3546C88.5496 6.02751 82.0113 -0.311573 76.437 1.17574C70.9023 2.661 68.5208 11.3709 71.0633 20.626C73.6028 29.8873 80.1274 36.2922 85.6527 34.7853Z"
        fill="#E5E6E8"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0363 58.8081C28.1865 57.6613 28.9576 51.9273 26.709 45.9619C24.4978 39.9843 20.1622 36.0688 16.9838 37.1922C13.7793 38.3482 13.0739 44.0771 15.2673 50.0272C17.4763 56.0079 21.8829 59.9315 25.0363 58.8081Z"
      fill="#E5E6E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.3752 48.8271C44.4098 47.7281 44.8792 41.5666 42.4375 34.9993C40.0143 28.4928 35.5664 24.0566 32.6007 25.1717C29.6062 26.2696 29.1162 32.4352 31.5559 38.9417C33.9862 45.4898 38.4023 49.9493 41.3752 48.8271Z"
      fill="#E5E6E8"
    />
    <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="12" height="20">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 50.8898H11.3131V69.8407H0V50.8898Z" fill="white" />
    </mask>
    <g mask="url(#mask2)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.78599 69.6477C11.904 68.5793 11.809 63.5572 9.56599 58.4035C7.23699 53.3172 3.68999 50.0572 1.52099 51.0761C-0.588005 52.1203 -0.506005 57.1456 1.78099 62.2824C4.05599 67.3866 7.63799 70.693 9.78599 69.6477Z"
        fill="#E5E6E8"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.3259 103.057C88.5996 83.5669 110.176 74.9523 102.341 53.6361C100.129 47.6248 94.9264 41.4826 85.7787 41C76.3827 41 71.6286 43.1524 64.0865 49.2813C56.7164 54.8826 54.7225 57.8294 42.5375 60.4695C31.1157 62.9471 -1.74073 67.6087 4.87032 107.575C6.47802 116.105 9.25469 122.662 12.9428 130H58.7629C60.6373 120.627 65.3255 111.936 72.3259 103.057Z"
      fill="#E5E6E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.855 160.86C157.873 191.041 140.295 190.143 131.551 214.254C127.698 226.318 128.59 234.829 131.702 240.916C131.777 241.094 131.805 241.285 131.891 241.459C138.01 254.739 153.558 260.564 166.858 254.754C166.877 254.747 166.903 254.739 166.914 254.739C167.085 254.665 167.257 254.643 167.42 254.551C167.557 254.495 167.666 254.4 167.804 254.334C175.746 250.896 180.667 242.959 183.587 234.991C190.559 215.823 189.078 196.493 195.71 178.389C198.247 171.392 200.682 165.349 203 160H169.14C169.04 160.289 168.961 160.568 168.855 160.86Z"
      fill="#E5E6E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M188.571 60.5285C176.421 57.8804 174.427 54.9256 167.083 49.3074C159.562 43.1589 154.82 41 145.452 41C136.327 41.4841 131.143 47.6449 128.935 53.6744C121.122 75.0551 142.636 83.6969 158.866 103.245C168.266 115.238 173.453 126.896 173.263 140.341H134.811C132.661 140.341 130.63 141.829 129.976 144.094L124.128 165.971L117.74 91.0453C117.44 88.9582 116.011 87.1706 113.92 86.6332C111.231 85.9183 108.425 87.5244 107.832 90.1489L93.0892 145.046L88.5477 128.237C88.3094 127.046 87.4775 125.734 86.2245 125.019C83.9568 123.592 80.7309 124.422 79.2992 126.808L71.2986 140.341H19C23.8014 149.633 29.5538 161.253 35.9368 178.782C42.5735 196.795 41.0946 216.028 48.0599 235.1C50.9808 243.029 55.8962 250.927 63.8383 254.346C63.9718 254.413 64.0858 254.508 64.2183 254.563C64.3836 254.655 64.5541 254.677 64.7236 254.75C64.74 254.75 64.7646 254.758 64.7842 254.765C78.0791 260.547 93.6202 254.75 99.7362 241.536C99.8173 241.364 99.845 241.173 99.9221 240.997C103.034 234.939 103.926 226.471 100.074 214.467C91.3361 190.476 73.7666 191.37 62.7835 161.339C61.4011 157.53 60.3802 153.891 59.6931 150.388L59.7168 150.412H74.1651C76.0723 150.412 77.7474 149.336 78.5844 147.788L81.9212 142.184L88.2539 165.909C88.9082 168.65 91.6524 170.2 94.3422 169.486C96.2484 169.01 97.5024 167.637 98.0395 165.909L110.038 120.552L116.549 195.476C116.786 198.217 119.293 200.304 122.037 200.127C124.243 199.889 126.034 198.217 126.573 196.074L138.694 150.412H207.849C216.696 132.024 223.27 123.054 226.133 107.778C232.721 67.6893 199.961 63.0146 188.571 60.5285Z"
      fill="#E5E6E8"
    />
  </svg>
)
