import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'redux-form'
import TextField from 'components/FormTextField'
import Button from '@material-ui/core/Button'
import { required, validateEmail } from 'utils/form'
import styles from './ContactForm.styles'
import Avatar from '@material-ui/core/Avatar'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(styles)

function ContactForm({ pristine, sending, handleSubmit }) {
  const classes = useStyles()

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <Field
        className={classes.field}
        name="name"
        component={TextField}
        autoComplete="name"
        label="Full Name*"
        validate={[required]}
      />
      <Field
        className={classes.field}
        name="email"
        component={TextField}
        autoComplete="email"
        label="Email*"
        validate={[required, validateEmail]}
      />
      <Field className={classes.field} name="phone" component={TextField} autoComplete="phone" label="Phone" />
      <Field
        className={classes.field}
        name="message"
        component={TextField}
        autoComplete="message"
        label="Message"
        multiline
        rows={10}
      />
      <div className={classes.submit}>
        <Button color="primary" type="submit" variant="contained" disabled={pristine || sending}>
          {sending ? 'Sending' : 'Send Message'}
        </Button>
      </div>
    </form>
  )
}

ContactForm.propTypes = {
  pristine: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  submitting: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  handleSubmit: PropTypes.func.isRequired // from enhancer (reduxForm - calls onSubmit)
}

export default ContactForm
