export default (theme) => ({
  page: {
    ...theme.page,
    backgroundImage: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('/img/bg-home.jpg')",
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'right',
    minHeight: '960px'
  },
  root: {
    ...theme.mainContainer,
    color: 'white'
  },
  section: {
    ...theme.flexColumnCenter,
    margin: '25px auto'
  },
  brandImg: {
    margin: '90px 0',
    width: '40%',
    '@media (max-width:800px)': {
      margin: '40px 0',
      width: '60%'
    },
    '@media (max-width:500px)': {
      margin: '40px 0',
      width: '90%'
    }
  },
  extraBold: {
    ...theme.fontWeight.extraBold
  },
  subTitle: {
    ...theme.fontWeight.regular,
    fontSize: '40px',
    textAlign: 'center',
    marginTop: '22px',
    marginBottom: '50px'
  },
  heroBox: {
    ...theme.heroBox,
    color: 'white',
    backgroundColor: '#212121',
    maxWidth: '900px'
  },
  heroGrid: {
    ...theme.flexRowCenter,
    margin: '60px 15px'
  },
  heroItem: {
    ...theme.flexColumnCenter,
    flexGrow: 1
  },
  heroIcon: {
    marginBottom: '16px'
  },
  testGrid: {
    ...theme.flexRowCenter
  }
})
